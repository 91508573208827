import _ from 'lodash';
import { ChangeEvent, ReactElement, useCallback } from 'react';

import { Slider as MuiSlider } from '@breathelife/mui';

import { text } from '../../Localization/Localizer';
import { SliderLabelsContainer, SliderWrapper } from './Styles';

type Props = {
  currentTooltipText?: string;
  labels?: string[];
  max: number;
  min: number;
  onChange: (data: number) => void;
  onDrag?: (data: number) => void;
  step: number;
  value: number;
};

export function getSnappedValue(value: number | undefined, min: number, max: number, step: number): number {
  if (_.isUndefined(value)) return min;
  if (value === min || value === max) return value;
  const roundedValue = _.round(value / step) * step;
  if (roundedValue > max) return max;
  if (roundedValue < min) return min;
  return roundedValue;
}

export function Slider(props: Props): ReactElement {
  const { currentTooltipText, labels, max, min, step, onChange, onDrag: onDragProp, value } = props;
  const sliderRefCallback = useCallback<(el: HTMLDivElement | null) => any>(
    (el) => {
      if (el) {
        el.getElementsByClassName('MuiSlider-thumb')[0].setAttribute('data-tooltip-text', currentTooltipText || '');
      }
    },
    [currentTooltipText],
  );

  const onMouseUp = useCallback(
    (_: ChangeEvent<any>, value: number | number[]) => {
      const newValue = getSnappedValue(Array.isArray(value) ? value[0] : value, min, max, step);
      onChange(newValue);
    },
    [onChange, min, max, step],
  );

  const onDrag = useCallback(
    (_: ChangeEvent<any>, value: number | number[]) => {
      if (!onDragProp) return;
      const newValue = getSnappedValue(Array.isArray(value) ? value[0] : value, min, max, step);
      onDragProp(newValue);
    },
    [onDragProp, min, max, step],
  );

  return (
    <div>
      <SliderWrapper ref={sliderRefCallback} hasTooltip={!!currentTooltipText}>
        <MuiSlider
          value={value}
          min={min}
          max={max}
          onChange={onDrag}
          onChangeCommitted={onMouseUp}
          aria-label={text('product.selectedCoverage.sliderLabel')}
        />
      </SliderWrapper>

      {labels && (
        <SliderLabelsContainer data-testid='label-container'>
          {labels.map((label) => (
            <span key={label}>{label}</span>
          ))}
        </SliderLabelsContainer>
      )}
    </div>
  );
}
