import { Box } from '@breathelife/mui';
import { ReactElement, Fragment, useCallback, useMemo } from 'react';

import { AddonsOptionUpsell, Language } from '@breathelife/types';
import { NavigationButtons } from '@breathelife/ui-components';

import { buildAddonCheckboxInitialStates, getTotalPremium, updateSelectAddons } from '../../Helpers/addonsHelper';
import { text } from '../../Localization/Localizer';
import { DefaultAddonView } from './DefaultAddonView';
import { PremiumChangeView } from './PremiumChangeView';
import { SelectableAddonView } from './SelectableAddonView';

type Props = {
  addons: AddonsOptionUpsell[];
  selectedAddons: string[];
  onAddonsChange: (selectedAddons: string[]) => void;
  onBackButtonClick: () => void;
  onNextClick: () => void;
  monthlyPremium: number | undefined;
  referencePremium: number | undefined;
  language: Language;
  nextStepButtonText?: string;
  isNextBtnLoading: boolean;
};

export function AddonsView(props: Props): ReactElement {
  const {
    addons,
    onBackButtonClick,
    onNextClick,
    monthlyPremium,
    referencePremium,
    selectedAddons,
    onAddonsChange,
    language,
    nextStepButtonText,
    isNextBtnLoading,
  } = props;

  const checkedAddons = useMemo(
    () => buildAddonCheckboxInitialStates(addons, selectedAddons),
    [addons, selectedAddons],
  );

  const totalPremium = useMemo(
    () => getTotalPremium(addons, checkedAddons, referencePremium),
    [addons, checkedAddons, referencePremium],
  );

  const onCheckboxChange = useCallback(
    (addonId) => {
      const updatedCheckedAddons = { ...checkedAddons, [addonId]: !checkedAddons[addonId] };
      const updatedSelectedAddons = updateSelectAddons(updatedCheckedAddons);
      onAddonsChange(updatedSelectedAddons);
    },
    [checkedAddons, onAddonsChange],
  );

  return (
    <Fragment>
      {addons.map((addon) => {
        if (addon.disabled) {
          return <DefaultAddonView key={addon.id} addon={addon} language={language} />;
        }

        return (
          <SelectableAddonView
            key={addon.id}
            addon={addon}
            language={language}
            checkedAddons={checkedAddons}
            onAddonChange={onCheckboxChange}
          />
        );
      })}

      <PremiumChangeView revisedPremium={totalPremium} hasPremiumChanged={totalPremium !== monthlyPremium} />

      <Box px={{ xs: 3, md: 0 }}>
        <NavigationButtons
          onPreviousClick={onBackButtonClick}
          hidePrevious={false}
          hideNext={false}
          nextButtonText={nextStepButtonText || text('continue')}
          onNextClick={onNextClick}
          isNextBtnLoading={isNextBtnLoading}
        />
      </Box>
    </Fragment>
  );
}
