import _ from 'lodash';
import { ReactElement, Fragment, useCallback, useContext, useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import config from '@breathelife/config/frontend';
import { hash } from '@breathelife/hash';
import { InsuranceApplicationStepId } from '@breathelife/insurance-form-builder';
import {
  areAnyFieldsAnsweredWithinSubsectionScope,
  deserializeNodeIdToAnswerPathMap,
  getAllAnswersWithinSubsectionScope,
  QuestionnaireEngine,
  SerializedNodeIdToAnswerPathMap,
} from '@breathelife/questionnaire-engine';
import { ButtonName, TypewriterTracking } from '@breathelife/frontend-tracking';
import { setA11yMessage } from '@breathelife/redux';
import { Answers, DEFAULT_TIMEZONE, QuestionnaireBlueprint, Timezone, VersionedAnswers } from '@breathelife/types';
import { GoogleMapsHelmet, PopupContext } from '@breathelife/ui-components';

import { CarrierContext } from '../../Context/CarrierContext';
import { createQuestionnaireFromSubsection } from '../../Helpers/createQuestionnaireFromSubsection';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { a11yNavigationPageTitle, shortLocale, text } from '../../Localization/Localizer';
import { StepProps } from '../../Models/Question';
import { refreshApplicationPremium } from '../../Redux/InsuranceApplication/InsuranceApplicationOperations';
import * as stepOperations from '../../Redux/Step/StepOperations';
import { StepPage } from './StepPage';

type Props = {
  stepId?: string;
};

// TODO: Replace this check with an interface with the questionnaire that uses node ids.
// This is a quick and dirty way of triggering the quote fetching for the price update step.
const PREMIUM_UPDATE_SUBSECTIONS = [InsuranceApplicationStepId.lifestyleTobaccoUse];

export function StepPageContainer(props: Props): ReactElement | null {
  const { stepId } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { popup } = useContext(PopupContext);
  const { layout } = useContext(CarrierContext);

  const stepState = useCxSelector((store) => store.consumerFlow.step);
  const { currentStep, hasErrors, isLoading } = stepState;

  const { landingStepsIds } = useCxSelector((store) => store.consumerFlow.configuration);
  const shouldShowLoadingPage = useCxSelector((store) => store.consumerFlow.navigation.loadingPage.isVisible);
  const progress = useCxSelector((store) => store.consumerFlow.progress.progress);
  const insuranceApplication = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication);

  const [shouldRefreshApplicationPremium, setShouldRefreshApplicationPremium] = useState<boolean>(false);
  const currentStepId = currentStep?.id;
  const applicationId = insuranceApplication?.id ?? null;
  const versionedAnswers: VersionedAnswers = useMemo(() => {
    return new VersionedAnswers({
      v1: insuranceApplication?.answers || {},
      v2: insuranceApplication?.answersV2 || {},
    });
  }, [insuranceApplication?.answers, insuranceApplication?.answersV2]);
  const nodeIdToAnswerPathMap = deserializeNodeIdToAnswerPathMap(
    insuranceApplication?.answersDataStructure as SerializedNodeIdToAnswerPathMap,
  );

  const googleMapsApiKey = config.get<string | undefined>('keys.googleMapsPlaces');

  const questionnaire = createQuestionnaireFromSubsection(currentStep);

  const blueprint: QuestionnaireBlueprint | undefined = insuranceApplication?.blueprint;

  const questionTitle = text(`a11y.questionTitle.${currentStep?.id}`);
  const pageTitle = `${questionTitle} - ${text('a11y.pageTitle.apply')}`;

  const lang = shortLocale();

  useEffect(() => {
    if (!applicationId || !currentStepId) return;
    TypewriterTracking.viewedStep({
      stepId: currentStepId ?? '',
      hashedId: hash(applicationId),
    });
  }, [applicationId, currentStepId]);

  useEffect(() => {
    if (!stepId) return;
    dispatch(stepOperations.setCurrentQuestionById(stepId, lang, navigate));
  }, [dispatch, stepId, lang, navigate]);

  const getHasAnswersChanged = useCallback(
    (stepId: string, newAnswers: Answers): boolean => {
      const previousScopedAnswers = getAllAnswersWithinSubsectionScope(
        questionnaire,
        stepId,
        versionedAnswers.v1,
        nodeIdToAnswerPathMap,
      );
      const newScopedAnswers = getAllAnswersWithinSubsectionScope(
        questionnaire,
        stepId,
        newAnswers,
        nodeIdToAnswerPathMap,
      );
      const anyFieldsWerePreviouslyAnswered = areAnyFieldsAnsweredWithinSubsectionScope(
        questionnaire,
        stepId,
        versionedAnswers.v1,
        nodeIdToAnswerPathMap,
      );

      const hasAnswerChanged = anyFieldsWerePreviouslyAnswered && !_.isEqual(newScopedAnswers, previousScopedAnswers);
      return hasAnswerChanged;
    },
    [versionedAnswers, questionnaire, nodeIdToAnswerPathMap],
  );

  const submitAnswers = useCallback(
    async (versionedAnswers: VersionedAnswers): Promise<void> => {
      if (!applicationId || !currentStep || !props.stepId) return;

      const hasAnswerChanged = getHasAnswersChanged(currentStep.id, versionedAnswers);

      TypewriterTracking.completedStep({
        hasChanged: hasAnswerChanged,
        hashedId: hash(applicationId),
        insuredPersonIndex: 0,
        stepId: currentStep.id,
      });

      dispatch(stepOperations.answerQuestionAndNavigate(stepId as string, versionedAnswers, navigate));

      const isPremiumUpdateStep = PREMIUM_UPDATE_SUBSECTIONS.includes(currentStep.id as InsuranceApplicationStepId);

      if (isPremiumUpdateStep || shouldRefreshApplicationPremium) {
        dispatch(refreshApplicationPremium());

        setShouldRefreshApplicationPremium(false);
      }
    },
    [
      applicationId,
      currentStep,
      props.stepId,
      getHasAnswersChanged,
      dispatch,
      stepId,
      shouldRefreshApplicationPremium,
      navigate,
    ],
  );

  const onAnswerComplete = useCallback(
    (fieldId: string) => {
      TypewriterTracking.completedField({
        fieldId,
        hashedId: hash(applicationId),
      });
    },
    [applicationId],
  );

  const onInfoIconClick = useCallback(() => {
    TypewriterTracking.clickedButton({
      hashedId: hash(applicationId),
      buttonName: ButtonName.moreInformation,
    });
  }, [applicationId]);

  const onFieldError = useCallback(
    (fieldId: string, error?: string) => {
      TypewriterTracking.errorOccurred({
        hashedId: hash(applicationId),
        error: `Field ${fieldId} error: ${error}`,
      });
    },
    [applicationId],
  );

  const navigateToPreviousQuestion = useCallback((): void => {
    dispatch(stepOperations.navigateToPreviousQuestion(stepId as string, lang, navigate));
  }, [dispatch, stepId, lang, navigate]);

  if (!currentStep || !stepId) return null;

  const questionProps: StepProps = {
    step: currentStep || {},
    submitAnswers,
    isLoading,
    versionedAnswers,
    onAnswerComplete,
    onInfoIconClick,
    onError: onFieldError,
  };

  const timezone = Timezone.from(Intl.DateTimeFormat().resolvedOptions().timeZone).withDefault(DEFAULT_TIMEZONE);

  const questionnaireEngine = new QuestionnaireEngine(
    questionnaire,
    nodeIdToAnswerPathMap,
    {},
    undefined,
    blueprint || { sectionGroupBlueprints: {}, sectionBlueprints: [] },
    timezone,
    insuranceApplication?.applicationContext,
  );

  dispatch(setA11yMessage(a11yNavigationPageTitle(pageTitle)));
  return (
    <Fragment>
      {googleMapsApiKey && <GoogleMapsHelmet googleMapsApiKey={googleMapsApiKey} />}
      <StepPage
        {...questionProps}
        key={questionProps.step.id}
        displayErrors={hasErrors}
        progress={progress}
        popup={popup}
        isLoading={isLoading}
        showLoadingPage={shouldShowLoadingPage}
        onBackButtonClick={navigateToPreviousQuestion}
        layout={layout}
        landingStepsIds={landingStepsIds}
        questionnaireEngine={questionnaireEngine}
        nodeIdToAnswerPathMap={nodeIdToAnswerPathMap}
        setShouldRefreshApplicationPremium={setShouldRefreshApplicationPremium}
      />
    </Fragment>
  );
}
